import React, { useState, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ServerContext } from '../contexts/ServerContext';
import SketchPad from '../components/SketchPad';
import ReactMarkdown from 'react-markdown';

const ChartQuestion = () => {
    const { id } = useParams();
    const { serverAddress } = useContext(ServerContext);
    const [feedbackText, setFeedbackText] = useState(null);
    const [grade, setGrade] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const sketchPadRef = useRef();

    const question = "Graph the effect of an improvement in production technology, using supply and demand curves.";

    const handleSubmit = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const base64Image = await sketchPadRef.current.getImageBase64();

            console.log('image: ', base64Image);

            const response = await fetch(`${serverAddress}/api/analyze-chart`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    image: base64Image, 
                    question: question 
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to get feedback');
            }

            const data = await response.json();
            console.log('data: ', data);
            setFeedbackText(data.feedback.feedback);
            setGrade(data.feedback.grade);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getLetterGrade = (numericGrade) => {
        if (numericGrade >= 97) return 'A+';
        if (numericGrade >= 93) return 'A';
        if (numericGrade >= 90) return 'A-';
        if (numericGrade >= 87) return 'B+';
        if (numericGrade >= 83) return 'B';
        if (numericGrade >= 80) return 'B-';
        if (numericGrade >= 77) return 'C+';
        if (numericGrade >= 73) return 'C';
        if (numericGrade >= 70) return 'C-';
        if (numericGrade >= 67) return 'D+';
        if (numericGrade >= 63) return 'D';
        if (numericGrade >= 60) return 'D-';
        return 'F';
    };

    const getGradeColor = (numericGrade) => {
        if (numericGrade >= 90) return '#4CAF50'; // Green
        if (numericGrade >= 80) return '#8BC34A'; // Light Green
        if (numericGrade >= 70) return '#FFEB3B'; // Yellow
        if (numericGrade >= 60) return '#FFC107'; // Amber
        return '#F44336'; // Red
    };

    return (
        <div className="chart-question">
            <h2>Economics Graph Question</h2>
            <p>{question}</p>
            <SketchPad ref={sketchPadRef} />
            <button onClick={handleSubmit}>Submit Answer</button>
            {isLoading && <p>Analyzing your graph...</p>}
            {error && <p className="error">Error: {error}</p>}
            {grade !== null && (
                <div 
                    className="grade" 
                    style={{
                        backgroundColor: getGradeColor(grade),
                        padding: '10px',
                        borderRadius: '5px',
                        marginTop: '10px',
                        color: 'white',
                        fontWeight: 'bold'
                    }}
                >
                    Grade: {getLetterGrade(grade)} ({grade}/100)
                </div>
            )}
            {feedbackText && (
                <div className="feedback">
                    <h3>Feedback</h3>
                    <ReactMarkdown>{feedbackText}</ReactMarkdown>
                </div>
            )}
        </div>
    );
};

export default ChartQuestion;
