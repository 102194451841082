import React, { createContext, useState } from 'react';

export const ServerContext = createContext();

export const ServerProvider = ({ children }) => {
//   const [serverAddress, setServerAddress] = useState('http://localhost:8000');
  const [serverAddress, setServerAddress] = useState('https://thorp-backend.vercel.app');

  return (
    <ServerContext.Provider value={{ serverAddress, setServerAddress }}>
      {children}
    </ServerContext.Provider>
  );
};

export default ServerProvider;
