import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Question from '../components/Question';
import { ServerContext } from '../contexts/ServerContext';
import '../styles/Topic.css';

function Topic() {
  const [topicData, setTopicData] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const { id } = useParams();
  const { serverAddress } = useContext(ServerContext);

  useEffect(() => {
    const fetchTopicData = async () => {
      try {
        const response = await axios.get(`${serverAddress}/data/topic/${id}`);
        setTopicData(response.data);
      } catch (error) {
        console.error('Error fetching topic data:', error);
      }
    };

    fetchTopicData();
  }, [id, serverAddress]);

  const handleNextQuestion = () => {
    if (topicData && currentQuestionIndex < topicData.topic_questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  if (!topicData) {
    return <div className="topic-container">Loading...</div>;
  }

  return (
    <div className="topic-container">
      <h1 className="topic-title">{topicData.title}</h1>
      <p className="topic-description">{topicData.description}</p>
      
      {topicData.topic_questions.length > 0 && (
        <Question 
          questionData={topicData.topic_questions[currentQuestionIndex].questions}
          onNextQuestion={handleNextQuestion}
        />
      )}
    </div>
  );
}

export default Topic;
