import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Courses from './scenes/Courses';
import CoursePage from './scenes/CoursePage';
import { ServerProvider } from './contexts/ServerContext';
import SectionPage from './scenes/Section';
import Topic from './scenes/Topic';
import SketchPad from './components/SketchPad';
import ChartQuestion from './scenes/ChartQuestion';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ServerProvider>
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/course/:id" element={<CoursePage />} />
          <Route path="/section/:id" element={<SectionPage />} />
          <Route path="/topic/:id" element={<Topic />} />
          <Route path="/sketchpad" element={<SketchPad />} />
          <Route path="/chart-question/:id" element={<ChartQuestion />} />
        </Routes>
      </Router>
    </ServerProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
