import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { ServerContext } from '../contexts/ServerContext';

const SectionPage = () => {
  const [sectionData, setSectionData] = useState(null);
  const { serverAddress } = useContext(ServerContext);
  const { id } = useParams();

  useEffect(() => {
    const fetchSectionData = async () => {
      try {
        const response = await axios.get(`${serverAddress}/data/section/${id}`);
        console.log('Section data:', response.data);
        setSectionData(response.data);
      } catch (error) {
        console.error('Error fetching section data:', error);
      }
    };

    fetchSectionData();
  }, [id, serverAddress]);

  if (!sectionData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>{sectionData.title}</h1>
      <h2>Topics:</h2>
      <ul>
        {sectionData.topics && sectionData.topics.map((topic) => (
          <li key={topic.id}>
            <Link to={`/topic/${topic.id}`}>{topic.title}</Link>
          </li>
        ))}
      </ul>
      <Link to={`/course/${sectionData.course_code}`}>Back to Course</Link>
    </div>
  );
};

export default SectionPage;

