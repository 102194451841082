import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ServerContext } from '../contexts/ServerContext';

const Courses = () => {
  const { serverAddress } = useContext(ServerContext);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${serverAddress}/data/courses`);
        console.log(response.data.data);
        setCourses(response.data.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching courses');
        setLoading(false);
      }
    };

    fetchCourses();
  }, [serverAddress]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h2>Courses</h2>
      <ul>
        {courses.map((course) => (
          <li key={course.id}>
            <a href={`/course/${course.code}`}>
              {course.title} - {course.instructor}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Courses;
