import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { ServerContext } from '../contexts/ServerContext';

const CoursePage = () => {
  const [courseData, setCourseData] = useState(null);
  const { serverAddress } = useContext(ServerContext);
  const { id } = useParams();
  console.log(id);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await axios.get(`${serverAddress}/data/course/${id}`);
        console.log('Course data:', response.data);
        setCourseData(response.data);
      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };

    fetchCourseData();
  }, [id, serverAddress]);  // Add serverAddress to the dependency array

  if (!courseData) {
    return <div>Loading...</div>;
  }

  // Sort sections by order
  const sortedSections = courseData.sections ? 
    [...courseData.sections].sort((a, b) => a.order - b.order) : 
    [];

  return (
    <div>
      <h1>{courseData.name}</h1>
      <h2>Sections:</h2>
      <ul>
        {sortedSections.map((section) => (
          <li key={section.id}>
            <Link to={`/section/${section.id}`}>{section.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CoursePage;
