import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';

const SketchPad = forwardRef(({ onSaveImage }, ref) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [color, setColor] = useState('#000000');
  const [lineWidth, setLineWidth] = useState(5);
  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [currentPath, setCurrentPath] = useState([]);
  const [tool, setTool] = useState('pencil');
  const [startPoint, setStartPoint] = useState(null);
  const [tempLineEnd, setTempLineEnd] = useState(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.lineCap = 'round';
      ctx.lineJoin = 'round';
      // Initialize history with blank canvas
      setHistory([canvas.toDataURL()]);
    }
  }, []);

  const getCoordinates = (e) => {
    if (!canvasRef.current) return { x: 0, y: 0 };
    const rect = canvasRef.current.getBoundingClientRect();
    const clientX = e.clientX || (e.touches && e.touches[0].clientX);
    const clientY = e.clientY || (e.touches && e.touches[0].clientY);
    return {
      x: clientX - rect.left,
      y: clientY - rect.top
    };
  };

  const saveCanvasState = () => {
    if (canvasRef.current) {
      setHistory([...history, canvasRef.current.toDataURL()]);
      setRedoStack([]);
    }
  };

  const startDrawing = (e) => {
    e.preventDefault();
    const { x, y } = getCoordinates(e);
    
    if (tool === 'line') {
      setStartPoint({ x, y });
      setTempLineEnd({ x, y });
      saveCanvasState();
    } else if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      ctx.beginPath();
      ctx.moveTo(x, y);
    }
    
    setIsDrawing(true);
    setCurrentPath([[x, y]]);
  };

  const draw = (e) => {
    if (!isDrawing) return;
    e.preventDefault();
    const { x, y } = getCoordinates(e);

    if (tool === 'line') {
      setTempLineEnd({ x, y });
      drawLine();
    } else if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      ctx.lineTo(x, y);
      ctx.strokeStyle = color;
      ctx.lineWidth = lineWidth;
      ctx.stroke();
    }

    setCurrentPath([...currentPath, [x, y]]);
  };

  const drawLine = () => {
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext('2d');
    
    // Clear the canvas and redraw the previous state
    const img = new Image();
    img.src = history[history.length - 1];
    img.onload = () => {
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      ctx.drawImage(img, 0, 0);
      
      // Draw the new line
      ctx.beginPath();
      ctx.moveTo(startPoint.x, startPoint.y);
      ctx.lineTo(tempLineEnd.x, tempLineEnd.y);
      ctx.strokeStyle = color;
      ctx.lineWidth = lineWidth;
      ctx.stroke();
    };
  };

  const endDrawing = () => {
    if (isDrawing) {
      if (tool === 'line') {
        drawLine(); // Finalize the line
      }
      saveCanvasState();
      setCurrentPath([]);
    }
    setIsDrawing(false);
    setStartPoint(null);
    setTempLineEnd(null);
  };

  const undo = () => {
    if (history.length > 1 && canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      const lastState = history[history.length - 2];
      setRedoStack([...redoStack, history[history.length - 1]]);
      setHistory(history.slice(0, -1));

      const img = new Image();
      img.src = lastState;
      img.onload = () => {
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        ctx.drawImage(img, 0, 0);
      };
    }
  };

  const redo = () => {
    if (redoStack.length > 0 && canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      const nextState = redoStack[redoStack.length - 1];
      setHistory([...history, canvasRef.current.toDataURL()]);
      setRedoStack(redoStack.slice(0, -1));

      const img = new Image();
      img.src = nextState;
      img.onload = () => {
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        ctx.drawImage(img, 0, 0);
      };
    }
  };

  const clearCanvas = () => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      saveCanvasState();
    }
  };

  const saveAsImage = () => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      
      // Create a new canvas with the same dimensions
      const newCanvas = document.createElement('canvas');
      newCanvas.width = canvas.width;
      newCanvas.height = canvas.height;
      const newCtx = newCanvas.getContext('2d');
      
      // Fill the new canvas with a white background
      newCtx.fillStyle = 'white';
      newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height);
      
      // Draw the original canvas content onto the new canvas
      newCtx.drawImage(canvas, 0, 0);
      
      // Convert the new canvas to a data URL
      const dataURL = newCanvas.toDataURL('image/png');
      
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = 'sketch.png';
      
      // Programmatically click the link to trigger the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const getImageBlob = () => {
    return new Promise((resolve) => {
      if (canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        
        // Create a new canvas with the same dimensions
        const newCanvas = document.createElement('canvas');
        newCanvas.width = canvas.width;
        newCanvas.height = canvas.height;
        const newCtx = newCanvas.getContext('2d');
        
        // Fill the new canvas with a white background
        newCtx.fillStyle = 'white';
        newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height);
        
        // Draw the original canvas content onto the new canvas
        newCtx.drawImage(canvas, 0, 0);
        
        newCanvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/png');
      } else {
        resolve(null);
      }
    });
  };

  const getImageBase64 = () => {
    return new Promise((resolve) => {
      if (canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        
        // Create a new canvas with the same dimensions
        const newCanvas = document.createElement('canvas');
        newCanvas.width = canvas.width;
        newCanvas.height = canvas.height;
        const newCtx = newCanvas.getContext('2d');
        
        // Fill the new canvas with a white background
        newCtx.fillStyle = 'white';
        newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height);
        
        // Draw the original canvas content onto the new canvas
        newCtx.drawImage(canvas, 0, 0);
        
        // Convert the new canvas to a base64 encoded PNG
        const base64Image = newCanvas.toDataURL('image/png').split(',')[1];
        resolve(base64Image);
      } else {
        resolve(null);
      }
    });
  };

  // Expose getImageBlob and getImageBase64 functions
  useImperativeHandle(ref, () => ({
    getImageBlob,
    getImageBase64
  }));

  const colors = ['#000000', '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF'];

  return (
    <div className="sketch-pad">
      <canvas
        ref={canvasRef}
        width={800}
        height={600}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={endDrawing}
        onMouseOut={endDrawing}
        onTouchStart={startDrawing}
        onTouchMove={draw}
        onTouchEnd={endDrawing}
        style={{ border: '1px solid #000', touchAction: 'none' }}
      />
      <div className="controls">
        <div className="color-options">
          {colors.map((c) => (
            <button
              key={c}
              style={{ backgroundColor: c, width: 30, height: 30, margin: 5 }}
              onClick={() => setColor(c)}
            />
          ))}
        </div>
        <button onClick={() => setColor('#FFFFFF')}>Eraser</button>
        <input
          type="range"
          min="1"
          max="20"
          value={lineWidth}
          onChange={(e) => setLineWidth(parseInt(e.target.value))}
        />
        <button onClick={undo} disabled={history.length === 0}>Undo</button>
        <button onClick={redo} disabled={redoStack.length === 0}>Redo</button>
        <button onClick={clearCanvas}>Clear</button>
        <button onClick={() => setTool('pencil')} style={{backgroundColor: tool === 'pencil' ? '#ddd' : '#fff'}}>Pencil</button>
        <button onClick={() => setTool('line')} style={{backgroundColor: tool === 'line' ? '#ddd' : '#fff'}}>Line</button>
        <button onClick={saveAsImage}>Save as Image</button>
      </div>
    </div>
  );
});

export default SketchPad;
