import React, { useState, useEffect } from 'react';

function Question({ questionData, onNextQuestion }) {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [randomizedAnswers, setRandomizedAnswers] = useState([]);

  useEffect(() => {
    // Randomize answers when questionData changes
    setRandomizedAnswers(shuffleArray([...questionData.answers]));
  }, [questionData]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleAnswerSelect = (index) => {
    setSelectedAnswer(index);
    setShowFeedback(true);
  };

  const handleNextQuestion = () => {
    setSelectedAnswer(null);
    setShowFeedback(false);
    onNextQuestion();
  };

  return (
    <div className="question-container">
      <h2 className="question-text">{questionData.question}</h2>
      <ul className="answer-list">
        {randomizedAnswers.map((answer, index) => (
          <li key={index} className="answer-item">
            <button 
              className="answer-button"
              onClick={() => handleAnswerSelect(index)}
              disabled={showFeedback}
            >
              {answer.option_text}
            </button>
          </li>
        ))}
      </ul>
      
      {showFeedback && (
        <div className={`feedback ${randomizedAnswers[selectedAnswer].correct ? 'correct' : 'incorrect'}`}>
          {randomizedAnswers[selectedAnswer].correct ? (
            <p>Correct!</p>
          ) : (
            <p>Incorrect. The correct answer is: {randomizedAnswers.find(a => a.correct).option_text}</p>
          )}
          <button className="next-button" onClick={handleNextQuestion}>Next Question</button>
        </div>
      )}
    </div>
  );
}

export default Question;
